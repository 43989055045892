import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

export default class extends HttpRequest {
  getOrderFulfillmentSetting (query) {
    this.setHeader(getAuthToken())
    return this.get('/order-fulfillment-settings', query)
  }

  deleteOrderFulfillmentSetting (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/order-fulfillment-settings?id=${id}`)
  }

  createOrderFulfillmentSetting (payload) {
    this.setHeader(getAuthToken())
    return this.post('/order-fulfillment-settings', payload)
  }

  updateOrderFulfillmentSetting (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/order-fulfillment-settings/${id}`, payload)
  }

  updatePriority (payloads) {
    this.setHeader(getAuthToken())
    return this.patch('/order-fulfillment-settings/priority', payloads)
  }
}
