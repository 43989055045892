<template>
  <div class="setting-detail">
    <v-data-table
      :headers="header"
      :items="warehouseData"
      :options.sync="options"
      :items-per-page="-1"
      :server-items-length="-1"
      :loading="loading"
      height="70vh"
      class="elevation-1 mb-5 draggable-table"
      hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />
          <v-btn
            color="success"
            class="mb-2 ml-2"
            width="110px"
            :loading="loading"
            :disabled="disableSaveWarehouse"
            @click="savePriorityWarehouse()">
            save
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:body="{ headers }">
        <draggable
          :list="warehouseData"
          group="warehouses"
          tag="tbody">
          <tr
            v-for="(item, index) in warehouseData"
            :key="`warehouse-index-${index}`">
            <td :width="headers[0].width">
              <v-switch
                v-model="warehouseData[index].status"
                dense
                true-value="active"
                false-value="inactive" />
            </td>
            <td
              class="text-start vertical-top pa-4"
              :width="headers[1].width">
              {{ item.id }}
            </td>
            <td
              class="text-start vertical-top pa-4"
              :width="headers[2].width">
              {{ item.name }}
            </td>
            <td
              class="text-center vertical-top pa-4"
              :width="headers[3].width">
              <v-text-field
                v-if="editIndex === index"
                v-model="warehouseData[index].offlineMinStock"
                :rules="numberFieldRules"
                hide-details
                class="text-center"
                dense
                :readonly="loading"
                label="Value" />
              <span v-else>{{ item.offlineMinStock }}</span>
            </td>
            <td
              class="text-center f vertical-top pa-4"
              :width="headers[4].width">
              <v-text-field
                v-if="editIndex === index"
                v-model="warehouseData[index].onlineMinStock"
                :rules="numberFieldRules"
                hide-details
                class="text-center"
                dense
                :readonly="loading"
                label="Value" />
              <span v-else>{{ item.onlineMinStock }}</span>
            </td>
            <td
              class="text-end vertical-top pa-4"
              :width="headers[5].width">
              <v-icon
                v-if="editIndex === index"
                class="mr-2"
                small
                @click="editIndex = null">
                mdi-close-circle-outline
              </v-icon>
              <v-icon
                v-else
                class="mr-2"
                small
                @click="editIndex = index">
                mdi-pencil
              </v-icon>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import WarehouseProvider from '@/resources/WarehouseProvider'
import OrderfulfillmentSetttingProvider from '@/resources/OrderfulfillmentSetttingProvider'
import Draggable from 'vuedraggable'

const WarehouseService = new WarehouseProvider()
const OrderfulfillmentSettingService = new OrderfulfillmentSetttingProvider()

export default {
  components: {
    Draggable
  },
  data () {
    return {
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 9999,
        sortBy: ['priority'],
        sortDesc: [false]
      },
      header: [
        {
          text: 'Active',
          value: 'active',
          width: 60,
          sortable: false
        },
        {
          text: 'id',
          value: 'id',
          width: 10,
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          width: 150,
          sortable: false
        },
        {
          text: 'Offline MinStock',
          value: 'offlineMinStock',
          align: 'center',
          width: 80,
          sortable: false
        },
        {
          text: 'Online MinStock',
          value: 'onlineMinStock',
          align: 'center',
          width: 80,
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 100,
          align: 'end'
        }
      ],
      editIndex: null,
      warehouseData: [],
      memoWarehouses: [],
      settings: [],
      numberFieldRules: [
        (v) => !!v || 'Required.',
        (v) => /^[0-9]+$/.test(v) || 'Invalid.'
      ],
      getting: false
    }
  },
  computed: {
    disableSaveWarehouse () {
      return JSON.stringify(this.memoWarehouses) === JSON.stringify(this.warehouseData) || this.getting
    }
  },
  created () {
    this.getWarehouse()
  },
  methods: {
    resetData () {
      this.memoWarehouses = []
      this.warehouseData = []
      this.settings = []
      this.editIndex = null
    },
    async getWarehouse () {
      this.resetData()
      try {
        this.loading = true
        const [{ data: warehouses }, { data: settings }] = await Promise.all([
          WarehouseService.getWarehouse(),
          OrderfulfillmentSettingService.getOrderFulfillmentSetting({ isDefault: 1 })
        ])
        const settingWarehouse = settings[0]
        this.settings = settings

        this.warehouseData = warehouses.map((warehouse, index) => {
          const foundIndex = settingWarehouse.warehouses.findIndex((sw) => sw.id === warehouse.id)
          return {
            id: warehouse.id,
            priority: foundIndex === -1 ? (settingWarehouse.warehouses.length + index + 1) : foundIndex,
            name: warehouse.name,
            offlineMinStock: foundIndex === -1 ? '0' : `${settingWarehouse.warehouses[foundIndex].offlineMinStock}`,
            onlineMinStock: foundIndex === -1 ? '0' : `${settingWarehouse.warehouses[foundIndex].onlineMinStock}`,
            status: foundIndex === -1 ? 'inactive' : settingWarehouse.warehouses[foundIndex].status,
            temp: warehouse.status
          }
        })

        this.warehouseData = this.warehouseData.sort((a, b) => a.priority - b.priority).filter((w) => w.status === 'active' || w.temp === 'active')
        this.memoWarehouses = new Array(...JSON.parse(JSON.stringify(this.warehouseData)))
      } catch (error) {
        this.$store.dispatch('Components/setErrorPage', error.code)
      } finally {
        this.loading = false
      }
    },
    async savePriorityWarehouse () {
      try {
        this.loading = true
        const { id } = this.settings[0]
        await OrderfulfillmentSettingService.updateOrderFulfillmentSetting(id,
          {
            warehouses: this.warehouseData.map((wh) => ({
              ...wh,
              offlineMinStock: +wh.offlineMinStock,
              onlineMinStock: +wh.onlineMinStock
            })),
            isDefault: true
          })
        this.getWarehouse()
      } catch (error) {
        this.$store.dispatch('Components/setErrorPage', error.code)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.setting-detail {
  background-color: yellow;
}
.vertical-top {
  vertical-align: top;
}
.text-center >>> input {
  text-align: center
}
.theme--light.v-data-table.draggable-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid rgba(0, 0, 0, 0.12);
}
</style>
