<template>
  <div>
    <div>
      <div class="d-flex justify-end pa-4">
        <v-btn
          color="info"
          class="mb-2"
          width="110px"
          :loading="loading"
          @click="() => {
            editItem = null
            dialog = true
          }">
          New
        </v-btn>
        <v-btn
          color="success"
          class="mb-2 ml-2"
          width="110px"
          :loading="loading"
          :disabled="disableSave"
          @click="savePrioritySetting()">
          save
        </v-btn>
      </div>
      <div class="table-header-wrap">
        <div :style="'width: ' + 60 + 'px'" />
        <div
          v-for="(header, index) of settingHeaders"
          :key="'header-' + index"
          :style="'width: ' + header.width + 'px'">
          {{ header.text }}
        </div>
      </div>
      <draggable
        :list="settings"
        group="setting"
        tag="div">
        <div
          v-for="(item, index) of settings"
          :key="'item-' + index">
          <div class="table-item-wrap">
            <div
              v-if="expandedIndex === index"
              :style="'width: ' + 60 + 'px'"
              class="cs-pointer text-center"
              @click="expandedIndex = null">
              -
            </div>
            <div
              v-else
              :style="'width: ' + 60 + 'px'"
              class="cs-pointer text-center"
              @click="expandedIndex = index">
              +
            </div>
            <div :style="'width: ' + settingHeaders[0].width + 'px'">
              {{ item[settingHeaders[0].value] }}
            </div>
            <div :style="'width: ' + settingHeaders[1].width + 'px'">
              {{ item.channels.join(', ') }}
            </div>
            <div
              :style="'width: ' + settingHeaders[2].width + 'px'"
              class="">
              <v-icon
                class="mr-2"
                small
                @click="editSetting(item)">
                mdi-pencil
              </v-icon>
              <gw-icon-hover
                class="sku-table-bin"
                icon-name="mdi-delete"
                icon-hover="mdi-delete-empty"
                small
                @click="confirmRemove(item)" />
            </div>
          </div>
          <div
            :class="{ expanded: expandedIndex === index }"
            class="item-detail">
            <table style="width: 100%;">
              <thead>
                <tr>
                  <td
                    v-for="(head, WHDIndex) of warehouseHeaders"
                    :key="`${WHDIndex}-${index}-warehouseHeader`"
                    class="pa-2"
                    :class="`text-${head.align || 'left'}`">
                    {{ head.text }}
                  </td>
                </tr>
              </thead>
              <draggable
                :list="item.warehouses"
                :group="`warehouses-${index}`"
                tag="tbody">
                <tr
                  v-for="(wh, whIndex) in item.warehouses"
                  :key="`warehouse-index-${index}-${whIndex}`">
                  <td>
                    <div class="d-flex justify-center">
                      <v-switch
                        v-model="settings[index].warehouses[whIndex].status"
                        dense
                        true-value="active"
                        false-value="inactive" />
                    </div>
                  </td>
                  <td>
                    {{ wh.id }}
                  </td>
                  <td>
                    {{ wh.name }}
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-if="editWarehouseIndex === whIndex"
                      v-model="settings[index].warehouses[whIndex].offlineMinStock"
                      :rules="numberFieldRules"
                      hide-details
                      class="text-center"
                      style="width: 80px; margin: auto;"
                      solo
                      dense
                      :readonly="loading"
                      label="Value" />
                    <span v-else>{{ wh.offlineMinStock }}</span>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-if="editWarehouseIndex === whIndex"
                      v-model="settings[index].warehouses[whIndex].onlineMinStock"
                      :rules="numberFieldRules"
                      hide-details
                      class="text-center"
                      style="width: 80px; margin: auto;"
                      solo
                      dense
                      :readonly="loading"
                      label="Value" />
                    <span v-else>{{ wh.onlineMinStock }}</span>
                  </td>
                  <td class="text-center vertical-top pa-4">
                    <v-icon
                      v-if="editWarehouseIndex === whIndex"
                      small
                      @click="editWarehouseIndex = null">
                      mdi-close-circle-outline
                    </v-icon>
                    <v-icon
                      v-else
                      small
                      @click="editWarehouseIndex = whIndex">
                      mdi-pencil
                    </v-icon>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
        </div>
      </draggable>
    </div>

    <v-dialog
      v-model="dialog"
      max-width="500px">
      <v-card>
        <validation-observer
          ref="attributeForm"
          tag="form"
          @submit.prevent="saveModalAction()">
          <v-card-title>
            <span class="headline"> New Item </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row v-if="dialog">
                <v-col :cols="12">
                  <v-autocomplete
                    v-model="formData.tag"
                    :items="tags"
                    dense
                    label="Tag"
                  ></v-autocomplete>
                </v-col>
                <v-col :cols="12">
                  <v-autocomplete
                    v-model="formData.channels"
                    :items="['web', 'online_international']"
                    dense
                    multiple
                    disabled
                    label="Channels"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              :loading="loading"
              text
              @click="closeFormModal()">
              Cancel
            </v-btn>
            <v-btn
              :loading="loading"
              color="blue darken-1"
              type="submit"
              text>
              Save
            </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import WarehouseProvider from '@/resources/WarehouseProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import OrderfulfillmentSetttingProvider from '@/resources/OrderfulfillmentSetttingProvider'
import Draggable from 'vuedraggable'

const WarehouseService = new WarehouseProvider()
const ProductAttributeService = new ProductAttributeProvider()
const OrderfulfillmentSettingService = new OrderfulfillmentSetttingProvider()

const settingHeaders = [
  {
    text: 'Tag',
    value: 'tag',
    width: 150,
    sortable: false
  },
  {
    text: 'Channels',
    value: 'channels',
    align: 'center',
    width: 150,
    sortable: false
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
    width: 100,
    align: 'end'
  }
]

const warehouseHeaders = [
  {
    text: 'Active',
    value: 'active',
    width: 60,
    align: 'center'
  },
  {
    text: 'id',
    value: 'id',
    width: 10
  },
  {
    text: 'Name',
    value: 'name',
    width: 150
  },
  {
    text: 'Offline MinStock',
    value: 'offlineMinStock',
    align: 'center',
    width: 80
  },
  {
    text: 'Online MinStock',
    value: 'onlineMinStock',
    align: 'center',
    width: 80
  },
  {
    text: 'Actions',
    value: 'actions',
    width: 100,
    align: 'center'
  }
]

export default {
  components: {
    Draggable
  },
  data () {
    return {
      loading: false,
      tags: [],
      warehouses: [],
      expandedIndex: null,
      itemDetailHeight: 0,
      options: {
        page: 1,
        itemsPerPage: 9999,
        sortBy: ['priority'],
        sortDesc: [false]
      },
      dialog: false,
      editData: null,
      formData: {
        tag: '',
        channels: ['web', 'online_international']
      },
      warehouseHeaders,
      settingHeaders,
      editWarehouseIndex: null,
      warehouseData: [],
      memoSettings: [],
      settings: [],
      numberFieldRules: [
        (v) => !!v || 'Required.',
        (v) => /^[0-9]+$/.test(v) || 'Invalid.'
      ]
    }
  },
  computed: {
    disableSave () {
      return JSON.stringify(this.memoSettings) === JSON.stringify(this.settings)
    }
  },
  async created () {
    this.resetData()
    await this.getWarehouse()
    this.getTags()
    this.getData()
  },
  methods: {
    resetData () {
      this.memoSettings = []
      this.settings = []
      this.expandedIndex = null
      this.editWarehouseIndex = null
      this.formData = {
        tag: '',
        channels: ['web', 'online_international']
      }
    },
    editSetting (item) {
      this.editItem = item
      this.formData = {
        tag: item.tag,
        channels: item.channels
      }
      this.dialog = true
    },
    async savePrioritySetting () {
      try {
        this.loading = true
        await Promise.all(
          this.settings.map(async (setting, index) => {
            await OrderfulfillmentSettingService.updateOrderFulfillmentSetting(setting.id, {
              ...setting,
              isDefault: false,
              priority: index + 1,
              warehouses: setting.warehouses.map((wh) => ({
                ...wh,
                offlineMinStock: +wh.offlineMinStock,
                onlineMinStock: +wh.onlineMinStock
              }))
            })
          })
        )

        this.dialog = false
        this.resetData()
        this.getData()
      } catch (error) {
        this.$store.dispatch('Components/setErrorPage', error.code)
      } finally {
        this.loading = false
      }
    },
    getWarehouseData (item) {
      let warehouseData = this.warehouses.map((warehouse, index) => {
        const foundIndex = item.warehouses.findIndex((sw) => sw.id === warehouse.id)
        return {
          id: warehouse.id,
          priority: foundIndex === -1 ? (item.warehouses.length + index + 1) : foundIndex,
          name: warehouse.name,
          offlineMinStock: foundIndex === -1 ? '0' : `${item.warehouses[foundIndex].offlineMinStock}`,
          onlineMinStock: foundIndex === -1 ? '0' : `${item.warehouses[foundIndex].onlineMinStock}`,
          status: foundIndex === -1 ? 'inactive' : item.warehouses[foundIndex].status,
          temp: warehouse.status
        }
      })

      warehouseData = warehouseData.sort((a, b) => a.priority - b.priority).filter((w) => w.status === 'active' || w.temp === 'active')

      return warehouseData
    },
    async confirmRemove (item) {
      this.$store.dispatch('Components/setModal', {
        value: true,
        title: 'Remove Setting',
        message: 'Do you want to remove this Setting',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.remove(item.id)
      })
    },
    async remove (id) {
      try {
        this.loading = true
        await OrderfulfillmentSettingService.deleteOrderFulfillmentSetting(id)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemove', error)
      } finally {
        this.resetData()
        this.getData()
        this.loading = false
      }
    },
    async saveModalAction () {
      const valid = await this.$refs.attributeForm.validate()
      if (!valid) return
      try {
        this.loading = true
        if (this.editItem !== null) {
          await OrderfulfillmentSettingService.updateOrderFulfillmentSetting(this.editItem.id, {
            ...this.formData,
            isDefault: false,
            warehouses: this.editItem.warehouses.map((wh) => ({
              ...wh,
              offlineMinStock: +wh.offlineMinStock,
              onlineMinStock: +wh.onlineMinStock
            }))
          })
        } else {
          await OrderfulfillmentSettingService.createOrderFulfillmentSetting({
            ...this.formData,
            isDefault: false,
            warehouses: []
          })
        }
        this.dialog = false
        this.resetData()
        this.getData()
      } catch (error) {
        console.error('getTags: ', error)
        this.$store.dispatch('Components/setErrorPage', error.code)
      } finally {
        this.loading = false
      }
    },
    async getTags () {
      try {
        this.loadingTags = true
        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999999,
          sortBy: ['id'],
          sortDesc: [true]
        })
        this.tags = data.results.map((r) => r.name)
      } catch (error) {
        console.error('getTags: ', error)
        this.$store.dispatch('Components/setErrorPage', error.code)
      } finally {
        this.loadingTags = false
      }
    },
    async getWarehouse () {
      try {
        this.loading = true
        const { data } = await WarehouseService.getWarehouse()
        this.warehouses = data
        this.itemDetailHeight = 100 + (this.warehouses.length * 72)
      } catch (error) {
        console.error('getSetting: ', error)
        this.$store.dispatch('Components/setErrorPage', error.code)
      } finally {
        this.loading = false
      }
    },
    async getData () {
      try {
        this.loading = true
        const { data: settings } = await OrderfulfillmentSettingService.getOrderFulfillmentSetting({ isDefault: 0 })
        this.settings = settings.map((st) => ({
          ...st,
          warehouses: this.getWarehouseData(st)
        }))

        this.memoSettings = new Array(...JSON.parse(JSON.stringify(this.settings)))
      } catch (error) {
        console.error('getSetting: ', error)
        this.$store.dispatch('Components/setErrorPage', error.code)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.table-header-wrap {
  margin-top: 12px;
  justify-content: space-between;
  align-items: center;
  color: #6d6d6d;
  font-weight: 500;
  background-color: antiquewhite;
  padding: 16px;
  height: 60px;
  display: flex;
}
.expanded {
  height: 500px !important;
  overflow: auto !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th, td {
  padding: .25rem;
  text-align: left;
  border: 1px solid #ccc;
}
tbody tr:nth-child(odd) {
  background: #eee;
}
.item-detail {
  height: 0;
  transition: all .4s;
  padding: 0px 40px;
  overflow: hidden;
}
.table-item-wrap {
  justify-content: space-between;
  align-items: center;
  color: #6d6d6d;
  /* font-weight: 500; */
  /* background-color: antiquewhite; */
  padding: 16px;
  height: 60px;
  display: flex;
}
.text-center >>> input {
  text-align: center
}
</style>
