<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tab :key="'Default'">
            Default
          </v-tab>
          <v-tab :key="'Custom'">
            Custom
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <OrderfulfillmentSettingDefault />
          </v-tab-item>
          <v-tab-item>
            <OrderfulfillmentSettingCustom />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderfulfillmentSettingDefault from '../components/OrderfulfillmentSettingDefault.vue'
import OrderfulfillmentSettingCustom from '../components/OrderfulfillmentSettingCustom.vue'

export default {
  components: {
    OrderfulfillmentSettingDefault,
    OrderfulfillmentSettingCustom
  },
  data () {
    return {
      tab: 'Default'
    }
  }
}
</script>

<style lang="css" scoped>
.menu {
  background-color: #363636;
  height: 80px;
  color: #fff;
}
</style>
